<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
    <!-- <FooterTabBar/> -->
  </div>
</template>
<script>
// import FooterTabBar from "@/components/footerTabBar.vue";
export default {
  // components:{FooterTabBar}
  data(){
    return{
      devicewidth:''
    }
  },
  created() {
    this.devicewidth = document.documentElement.clientWidth;
    this.bodyScale();

    window.onresize = () => {
      return (() => {
        this.devicewidth = document.body.clientWidth;
        this.bodyScale();
      })()
    }

  },
  methods:{
    bodyScale() {
      var devicewidth = document.documentElement.clientWidth;
      var scale = devicewidth / 1920;
      document.body.style.zoom = scale;
    }

  }
};
</script>
<style>
  /*html {*/
  /*  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);*/
  /*  -webkit-filter: grayscale(100%);*/
  /*}*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
a:hover{
 text-decoration: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
