
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
// 原官网路由页面配置
// import router from './router';
import router from '@/router/ld.js';
import '@/assets/iconfont/iconfont.css';
import '@/assets/scss/ld.scss';
// import Error from "@/components/error/error.vue";
import 'swiper/dist/css/swiper.css'
import animate from 'animate.css';
import moment from 'moment'
import AMap from 'vue-amap'; // 高德
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

//地图key
AMap.initAMapApiLoader({
    key: 'e2a3fc8be2ea50da0ad5215cc39de519',//密钥
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType',
        'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'
    ],
    v: '1.4.15'
});
Vue.use(AMap);
Vue.use(VueQuillEditor)
Vue.prototype.$moment = moment;
// Vue.component('Error', Error);
// Vue.use(animate)
Vue.config.productionTip = false;
new Vue({
    router,
    render: h => h(App)

}).$mount('#app');
